import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from '../../auth/authConfig';

const AuthGuard = ({ children }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.error('Login failed:', e);
            });
        }
    }, [isAuthenticated, inProgress, instance]);

    if (isAuthenticated) {
        return children;
    }

    return null;
};

export default AuthGuard; 