export const msalConfig = {
    auth: {
        clientId: window.REACT_APP_CLIENT_ID,
        authority: window.REACT_APP_AUTHORITY,
        redirectUri: process.env.NODE_ENV === 'development' 
            ? 'http://localhost:3000'
            : window.location.origin,
        postLogoutRedirectUri: process.env.NODE_ENV === 'development'
            ? 'http://localhost:3000'
            : window.location.origin,
        validateAuthority: false,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: ["openid", "profile"]
};

export const protectedResources = {
    api: {
        endpoint: window.REACT_APP_API_BASE_URL,
        scopes: [`api://${window.REACT_APP_CLIENT_ID}/access_as_user`],
    },
}; 