import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, useMemo, useCallback } from "react";
import { Stage, Layer, Rect, Text, Image, Group } from "react-konva";
import "./Canvas.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PropTypes from "prop-types";
import bin_icon from "../../ressources/bin_icon_inverted.png";

const Canvas = forwardRef(({ imgSrc, option, onZoom, scale, positionX, positionY, isLocked }, ref) => {
	const [shapesCount, setShapesCount] = useState(0);
	const [isDrawing, setIsDrawing] = useState(false);
	const [rectangles, setRectangles] = useState([]);
	const [image, setImage] = useState(null);
	const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
	const stageRef = useRef(null);
	const transformWrapperRef = useRef(null);
	const [startPos, setStartPos] = useState({ x: 0, y: 0 });
	const [currentRect, setCurrentRect] = useState(null);
	const [transformState, setTransformState] = useState({
		scale: scale || 1,
		positionX: positionX || 0,
		positionY: positionY || 0
	});
	const imageRef = useRef(null);
	const [binIcon, setBinIcon] = useState(null);

	const getDeleteButtonSize = () => {
		const baseSize = 30;
		const scaledSize = baseSize / transformState.scale; // Adjust size based on zoom
		return {
			size: scaledSize,
			offset: scaledSize / 2 // half of size for positioning
		};
	};

	function zoomToFitAndCenter(image) {
		if (!transformWrapperRef.current || !image) return;

		const controlsSection = document.getElementsByClassName("controls-section")[0];
		const canvasWidth = window.innerWidth / 2 - (controlsSection?.clientWidth ?? 0);
		const canvasHeight = window.innerHeight;
		const scaleX = canvasWidth / image.width;
		const scaleY = canvasHeight / image.height;
		const initialScale = Math.min(scaleX, scaleY);

		const offsetX = (canvasWidth - image.width * initialScale) / 2;
		const offsetY = (canvasHeight - image.height * initialScale) / 2;

		setTransformState({
			scale: initialScale,
			positionX: offsetX,
			positionY: offsetY
		});

		transformWrapperRef.current.setTransform(
			offsetX,
			offsetY,
			initialScale,
			0
		);
	}

	useEffect(() => {
		const img = new window.Image();
		img.crossOrigin = "Anonymous";

		img.onload = () => {
			if (imageRef.current) {
				imageRef.current.image(img);
				imageRef.current.getLayer().batchDraw();
			} else {
				setImage(img);
				setStageSize({
					width: img.width,
					height: img.height
				});
			}

			if (transformWrapperRef.current) {
				if (transformState.scale !== 1) {
					transformWrapperRef.current.setTransform(
						transformState.positionX,
						transformState.positionY,
						transformState.scale,
						0
					);
				} else {
					zoomToFitAndCenter(img);
				}
			}
		};

		img.src = imgSrc;
	}, [imgSrc]);

	useEffect(() => {
		if (!onZoom || !transformWrapperRef.current) return;

		const zoomControls = {
			zoomIn: () => transformWrapperRef.current.zoomIn(),
			zoomOut: () => transformWrapperRef.current.zoomOut()
		};

		onZoom(zoomControls);
	}, []);

	useEffect(() => {
		const img = new window.Image();
		img.src = bin_icon;
		img.onload = () => {
			setBinIcon(img);
		};
	}, []);

	useEffect(() => {
		if (scale !== undefined && positionX !== undefined && positionY !== undefined) {
			setTransformState({
				scale,
				positionX,
				positionY
			});

			if (transformWrapperRef.current) {
				transformWrapperRef.current.setTransform(
					positionX,
					positionY,
					scale,
					0
				);
			}
		}
	}, [scale, positionX, positionY]);

	const handleMouseDown = (e) => {
		if (option !== "rect" && option !== "barcodeRect") {
			e.evt.preventDefault();
			e.evt.stopPropagation();
			return;
		}

		setIsDrawing(true);
		const pos = e.target.getStage().getPointerPosition();
		setStartPos({ x: pos.x, y: pos.y });

		const newRect = {
			x: pos.x,
			y: pos.y,
			width: 0,
			height: 0,
			fill: option === "rect" ? "rgba(211, 211, 211, 0.5)" : "rgba(162, 84, 158, 0.3)",
			stroke: option === "rect" ? "#A9A9A9" : "purple",
			strokeWidth: 4,
			id: `rect_${shapesCount + 1}`,
			isBarcode: option === "barcodeRect"
		};
		setCurrentRect(newRect);
	};

	const handleMouseMove = useCallback((e) => {
		if (!isDrawing) return;

		const stage = e.target.getStage();
		const pos = stage.getPointerPosition();

		requestAnimationFrame(() => {
			setCurrentRect(prev => ({
				...prev,
				x: Math.min(startPos.x, pos.x),
				y: Math.min(startPos.y, pos.y),
				width: Math.abs(pos.x - startPos.x),
				height: Math.abs(pos.y - startPos.y),
			}));
		});
	}, [isDrawing, startPos]);

	const handleMouseUp = () => {
		if (!isDrawing) return;
		setIsDrawing(false);

		if (currentRect && (currentRect.width > 0 || currentRect.height > 0)) {
			currentRect.scale = transformState.scale;
			setRectangles([...rectangles, currentRect]);
			if (option === "rect") {
				setShapesCount(shapesCount + 1);
			}
		}
		setCurrentRect(null);
	};

	const handleClear = () => {
		setRectangles([]);
		setShapesCount(0);
	};

	const handleDeleteRect = (index) => {
		const newRectangles = rectangles.filter((_, i) => i !== index);
		setRectangles(newRectangles);
		if (!rectangles[index].isBarcode) {
			setShapesCount(shapesCount - 1);
		}
	};

	const handleResetZoom = useCallback(() => {
		if (!transformWrapperRef.current || !image) return;

		zoomToFitAndCenter(image);
	}, [image]);

	useImperativeHandle(ref, () => ({
		handleClear,
		getRectCoordinates: () => {
			return rectangles.map(rect => ({
				left: rect.x,
				top: rect.y,
				width: rect.width,
				height: rect.height,
				isBarcode: rect.isBarcode || false,
				scale: rect.scale,
			}));
		},
		getCanvasDimensions: () => ({
			width: stageSize.width,
			height: stageSize.height,
		}),
		resetTransform: handleResetZoom,
		getInitialTransform: () => ({
			scale: transformState.scale,
			positionX: transformState.positionX,
			positionY: transformState.positionY
		}),
		getTransformWrapper: () => transformWrapperRef.current,
	}));

	const staticRectangles = useMemo(() => {
		return rectangles.map((rect, i) => {
			const deleteButtonSize = getDeleteButtonSize();
			return (
				<Group key={`rect_group_${i}`}>
					<Rect {...rect} />
					{!rect.isBarcode && (
						<Text
							text={`${i + 1}`}
							x={rect.x}
							y={rect.y}
							fontSize={Math.min(rect.height / 4, rect.width / 2)}
							width={rect.width}
							height={rect.height}
							fill="black"
							align="center"
							verticalAlign="middle"
							fontFamily="Chanel M"
						/>
					)}
					<Group
						x={rect.x + rect.width - deleteButtonSize.offset}
						y={rect.y - deleteButtonSize.offset}
						onClick={() => handleDeleteRect(i)}
						onTap={() => handleDeleteRect(i)}
						hitStrokeWidth={20}
					>
						<Rect
							width={deleteButtonSize.size}
							height={deleteButtonSize.size}
							fill="red"
							cornerRadius={9999}
						/>
						{binIcon && (
							<Image
								image={binIcon}
								width={deleteButtonSize.size * 0.75}
								height={deleteButtonSize.size * 0.75}
								x={deleteButtonSize.size * 0.125}
								y={deleteButtonSize.size * 0.125}
							/>
						)}
					</Group>
				</Group>
			);
		});
	}, [rectangles, binIcon, transformState.scale]);

	useEffect(() => {
		return () => {
			if (handleMouseMove.cancel) {
				handleMouseMove.cancel();
			}
		};
	}, [handleMouseMove]);

	return (
		<div className="imageEditor">
			<div className="transform-wrapper-container">
				<TransformWrapper
					ref={transformWrapperRef}
					limitToBounds={false}
					initialScale={transformState.scale}
					minScale={0.1}
					maxScale={6}
					disabled={isDrawing}
					panning={{ disabled: isLocked }}
					pinch={{ disabled: isLocked }}
					wheel={{ disabled: isLocked }}
					onTransformed={(e) => {
						if (isLocked) return;
						setTransformState({
							scale: e.state.scale,
							positionX: e.state.positionX,
							positionY: e.state.positionY
						});
					}}
				>
					<TransformComponent
						wrapperStyle={{ width: "100%", height: "100%" }}
					>
						<Stage
							className="canvas-container"
							ref={stageRef}
							width={stageSize.width}
							height={stageSize.height}
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							style={{
								pointerEvents: option === "move" ? "none" : "auto"
							}}
							listening={!isLocked || option === "rect" || option === "barcodeRect"}
						>
							<Layer listening={false}>
								{image && (
									<Image
										ref={imageRef}
										image={image}
										width={stageSize.width}
										height={stageSize.height}
										listening={false}
									/>
								)}
							</Layer>
							<Layer>
								{staticRectangles}
								{currentRect && <Rect {...currentRect} />}
							</Layer>
						</Stage>
					</TransformComponent>
				</TransformWrapper>
			</div>
		</div>
	);
});

Canvas.propTypes = {
	imgSrc: PropTypes.string.isRequired,
	option: PropTypes.string.isRequired,
	onZoom: PropTypes.func,
	onClear: PropTypes.func,
	scale: PropTypes.number,
	positionX: PropTypes.number,
	positionY: PropTypes.number,
	isLocked: PropTypes.bool
};

Canvas.displayName = "Canvas";
export default Canvas;
