import axios from "axios";

export const processPdf = async (pdfFile) => {
	try {
		const formData = new FormData();
		formData.append("pdf_file", pdfFile, pdfFile.name);
		const response = await axios.post(`${window.REACT_APP_API_BASE_URL}/process-pdf`, formData);
		return response.data.layer_configs;
	} catch (error) {
		console.error(error);
		throw error.response?.data?.detail || error.message || "Failed to process PDF";
	}
};

export const getPageCount = async (pdfData) => {
	try {
		const formData = new FormData();
		formData.append("pdf_file", pdfData, "document.pdf");
		const response = await axios.post(`${window.REACT_APP_API_BASE_URL}/page-count`, formData);
		return response.data.page_count;
	} catch (error) {
		console.error(error);
		throw error.response?.data?.detail || error.message || "Failed to get page count";
	}
};

export const pdfToImage = async (pdfData, numPage, layersToDisable) => {
	try {
		const formData = new FormData();
		formData.append("pdf_file", pdfData, "document.pdf");
		const layersToDisableStr = layersToDisable ? layersToDisable.join(",") : "";
		formData.append("num_page", numPage);
		formData.append("layers_to_disable", layersToDisableStr);

		const response = await axios.post(
			`${window.REACT_APP_API_BASE_URL}/pdf-to-image`,
			formData,
			{
				responseType: "blob", // Important pour recevoir une image
			}
		);

		if (response.status === 200) {
			const imageUrl = URL.createObjectURL(response.data);
			return imageUrl;
		} else {
			throw new Error("Failed to convert PDF to image");
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const extractImagesFromPdf = async (
	pdfFile1,
	pdfFile2,
	coords1,
	coords2,
	selectedPage1,
	selectedPage2,
	disabledLayers1,
	disabledLayers2,
	team,
) => {
	try {
		const formData1 = new FormData();
		formData1.append("pdf_file", pdfFile1);
		formData1.append("num_page", selectedPage1); // Ajuster si la page commence à 0 ou 1
		formData1.append("left", coords1.left);
		formData1.append("top", coords1.top);
		formData1.append("width", coords1.width);
		formData1.append("height", coords1.height);
		formData1.append("mode", "canvas");
		formData1.append("layers_to_disable", disabledLayers1.join(","));
		formData1.append("team", team);

		const formData2 = new FormData();
		formData2.append("pdf_file", pdfFile2);
		formData2.append("num_page", selectedPage2); // Ajuster si la page commence à 0 ou 1
		formData2.append("left", coords2.left);
		formData2.append("top", coords2.top);
		formData2.append("width", coords2.width);
		formData2.append("height", coords2.height);
		formData2.append("mode", "canvas");
		formData2.append("layers_to_disable", disabledLayers2.join(","));
		formData2.append("team", team);

		const [response1, response2] = await Promise.all([
			axios.post(`${window.REACT_APP_API_BASE_URL}/extract-image`, formData1, {
				responseType: "blob",
			}),
			axios.post(`${window.REACT_APP_API_BASE_URL}/extract-image`, formData2, {
				responseType: "blob",
			}),
		]);

		if (response1.status === 200 && response2.status === 200) {
			const img1 = URL.createObjectURL(response1.data);
			const img2 = URL.createObjectURL(response2.data);
			return [img1, img2];
		} else {
			throw new Error("Failed to extract images");
		}
	} catch (error) {
		console.error(error);
		return [null, null];
	}
};

export const alignAndOverlayImages = async (
	image1Url,
	image2Url,
	alpha = 0.5
) => {
	try {
		const response1 = await fetch(image1Url);
		const response2 = await fetch(image2Url);
		const image1Blob = await response1.blob();
		const image2Blob = await response2.blob();

		const formData = new FormData();
		formData.append("image1", image1Blob, "image1.png");
		formData.append("image2", image2Blob, "image2.png");
		formData.append("alpha", alpha);

		const response = await axios.post(
			`${window.REACT_APP_API_BASE_URL}/align_and_overlay_images`,
			formData
		);
		if (response.status === 200) {
			const resultImageBase64 = response.data.result_image;
			const transformedImage1Base64 = response.data.transformed_image1;
			const transformedImage2Base64 = response.data.transformed_image2;

			const resultImageBlob = await fetch(
				`data:image/png;base64,${resultImageBase64}`
			).then((res) => res.blob());
			const transformedImage1Blob = await fetch(
				`data:image/png;base64,${transformedImage1Base64}`
			).then((res) => res.blob());
			const transformedImage2Blob = await fetch(
				`data:image/png;base64,${transformedImage2Base64}`
			).then((res) => res.blob());

			const resultImageUrl = URL.createObjectURL(resultImageBlob);
			const transformedImage1Url = URL.createObjectURL(transformedImage1Blob);
			const transformedImage2Url = URL.createObjectURL(transformedImage2Blob);

			return { resultImageUrl, transformedImage1Url, transformedImage2Url };
		} else {
			throw new Error("Failed to align and overlay images");
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const highlightErrors = async (
	superposedImageUrl,
	image1Url,
	image2Url
) => {
	try {
		const responseSuperposed = await fetch(superposedImageUrl);
		const response1 = await fetch(image1Url);
		const response2 = await fetch(image2Url);
		const superposedImageBlob = await responseSuperposed.blob();
		const image1Blob = await response1.blob();
		const image2Blob = await response2.blob();

		const formData = new FormData();
		formData.append(
			"superposed_image",
			superposedImageBlob,
			"superposed_image.png"
		);
		formData.append("image1", image1Blob, "image1.png");
		formData.append("image2", image2Blob, "image2.png");

		const response = await axios.post(
			`${window.REACT_APP_API_BASE_URL}/highlight_errors`,
			formData
		);
		if (response.status === 200) {
			const outputImageBase64 = response.data.output_image;
			const threshImageBase64 = response.data.thresh_image;

			const outputImageBlob = await fetch(
				`data:image/png;base64,${outputImageBase64}`
			).then((res) => res.blob());
			const threshImageBlob = await fetch(
				`data:image/png;base64,${threshImageBase64}`
			).then((res) => res.blob());

			const outputImageUrl = URL.createObjectURL(outputImageBlob);
			const threshImageUrl = URL.createObjectURL(threshImageBlob);

			return { outputImageUrl, threshImageUrl };
		} else {
			throw new Error("Failed to highlight errors");
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const overlayAndHighlightErrors = async (
	image1Url,
	image2Url,
	alpha = 0.5,
	mode
) => {
	try {
		const response1 = await fetch(image1Url);
		const response2 = await fetch(image2Url);
		const image1Blob = await response1.blob();
		const image2Blob = await response2.blob();

		const formData = new FormData();
		formData.append("image1", image1Blob, "image1.png");
		formData.append("image2", image2Blob, "image2.png");
		
		formData.append("alpha", alpha);
		formData.append("mode", mode);

		const response = await axios.post(
			`${window.REACT_APP_API_BASE_URL}/overlay-and-highlight-errors`,
			formData
		);

		const {
			transformed_image1: transformedImage1Base64,
			transformed_image2: transformedImage2Base64,
			output_image: outputImageBase64,
			thresh_image: threshImageBase64,
		} = response.data;

		const transformedImage1Blob = await fetch(
			`data:image/png;base64,${transformedImage1Base64}`
		).then((res) => res.blob());
		const transformedImage2Blob = await fetch(
			`data:image/png;base64,${transformedImage2Base64}`
		).then((res) => res.blob());
		const outputImageBlob = await fetch(
			`data:image/png;base64,${outputImageBase64}`
		).then((res) => res.blob());
		const threshImageBlob = await fetch(
			`data:image/png;base64,${threshImageBase64}`
		).then((res) => res.blob());

		const transformedImage1Url = URL.createObjectURL(transformedImage1Blob);
		const transformedImage2Url = URL.createObjectURL(transformedImage2Blob);
		const outputImageUrl = URL.createObjectURL(outputImageBlob);
		const threshImageUrl = URL.createObjectURL(threshImageBlob);

		return {
			transformedImage1Url,
			transformedImage2Url,
			outputImageUrl,
			threshImageUrl,
		};
	} catch (error) {
		throw new Error(error.response?.data?.error || error.response?.data?.detail || error.message || "Failed to overlay and highlight errors");
	}
};

export const compare_images = async (image1Url, image2Url) => {
	try {
		const response1 = await fetch(image1Url);
		const response2 = await fetch(image2Url);
		const image1Blob = await response1.blob();
		const image2Blob = await response2.blob();

		const formData = new FormData();
		formData.append("image1", image1Blob, "image1.png");
		formData.append("image2", image2Blob, "image2.png");

		const response = await axios.post(
			`${window.REACT_APP_API_BASE_URL}/compare-images`,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);

		if (response.status === 200) {
			const {
				result_image: resultImageBase64,
				thresh_image: threshImageBase64,
			} = response.data;

			const resultBlob = await fetch(
				`data:image/png;base64,${resultImageBase64}`
			).then((res) => res.blob());
			const threshBlob = await fetch(
				`data:image/png;base64,${threshImageBase64}`
			).then((res) => res.blob());

			const resultUrl = URL.createObjectURL(resultBlob);
			const threshUrl = URL.createObjectURL(threshBlob);

			return { resultUrl, threshUrl };
		} else {
			throw new Error(response.data.error || response.data.detail || "Failed to compare images");
		}
	} catch (error) {
		throw new Error(error.response?.data?.error || error.response?.data?.detail || error.message || "Failed to compare images");
	}
};

export const compare_crops = async (crop1Url, crop2Url) => {
	try {
	  const response1 = await fetch(crop1Url);
	  const response2 = await fetch(crop2Url);
  
	  const crop1Blob = await response1.blob();
	  const crop2Blob = await response2.blob();
  
	  const formData = new FormData();
	  formData.append("crop1", crop1Blob, "crop1.png");
	  formData.append("crop2", crop2Blob, "crop2.png");
  
	  const response = await axios.post(
		`${window.REACT_APP_API_BASE_URL}/compare-crops`,
		formData,
		{
		  headers: {
			"Content-Type": "multipart/form-data",
		  },
		}
	  );
  
	  if (response.status === 200) {
		const {
		  // On récupère les nouveaux champs ici :
		  img1_aligned: img1AlignedBase64,
		  img2_aligned: img2AlignedBase64,
		  result_image: resultImageBase64,
		  thresh_image: threshImageBase64,
		  shift,
		  error,
		} = response.data;
  
		// Convertir le base64 en Blob puis en Object URL
		const img1AlignedBlob = img1AlignedBase64
		  ? await fetch(`data:image/png;base64,${img1AlignedBase64}`).then((r) => r.blob())
		  : null;
		const img2AlignedBlob = img2AlignedBase64
		  ? await fetch(`data:image/png;base64,${img2AlignedBase64}`).then((r) => r.blob())
		  : null;
  
		const img1AlignedUrl = img1AlignedBlob ? URL.createObjectURL(img1AlignedBlob) : null;
		const img2AlignedUrl = img2AlignedBlob ? URL.createObjectURL(img2AlignedBlob) : null;
  
		// idem pour result_image et thresh_image
		const resultBlob = await fetch(`data:image/png;base64,${resultImageBase64}`).then(
		  (res) => res.blob()
		);
		const threshBlob = await fetch(`data:image/png;base64,${threshImageBase64}`).then(
		  (res) => res.blob()
		);
  
		const resultUrl = URL.createObjectURL(resultBlob);
		const threshUrl = URL.createObjectURL(threshBlob);
  
		return {
		  img1AlignedUrl,
		  img2AlignedUrl,
		  resultUrl,
		  threshUrl,
		  shift,
		  error,
		};
	  } else {
		throw new Error(
		  response.data.error ||
			response.data.detail ||
			"Failed to compare crops"
		);
	  }
	} catch (error) {
	  throw new Error(
		error.response?.data?.error || 
		error.response?.data?.detail || 
		error.message || 
		"Failed to compare crops"
	  );
	}
  };  

export const gradeBarcode = async (image1Url, image2Url) => {
	try {
		const response1 = await fetch(image1Url);
		const response2 = await fetch(image2Url);
		const image1Blob = await response1.blob();
		const image2Blob = await response2.blob();

		const formData = new FormData();
		formData.append("extracted_img1", image1Blob, "image1.png");
		formData.append("extracted_img2", image2Blob, "image2.png");

		const response = await axios.post(
			`${window.REACT_APP_API_BASE_URL}/grade-barcode`,
			formData
		);

		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error("Failed to grade barcode");
		}
	} catch (error) {
		console.error("An error occurred:", error);
		return null;
	}
};

const urlToBlob = async (url) => {
	const response = await fetch(url);
	return response.blob();
};

export const groupedErrorTiles = async (
	threshImageUrl,
	originalImageUrl,
	image1Url,
	transformedImage2Url
) => {
	try {
		const threshBlob = await urlToBlob(threshImageUrl);
		const originalBlob = await urlToBlob(originalImageUrl);
		const image1Blob = await urlToBlob(image1Url);
		const transformedImage2Blob = await urlToBlob(transformedImage2Url);

		const formData = new FormData();
		formData.append("thresh", threshBlob, "thresh.png");
		formData.append("original_image", originalBlob, "original_image.png");
		formData.append("image1", image1Blob, "image1.png");
		formData.append(
			"transformed_image2",
			transformedImage2Blob,
			"transformed_image2.png"
		);

		const response = await axios.post(
			`${window.REACT_APP_API_BASE_URL}/grouped-error-tiles`,
			formData
		);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error("Failed to group error tiles");
		}
	} catch (error) {
		console.error("An error occurred:", error);
		return null;
	}
};

export const logExecutionTimeToBackend = async (logData) => {
	try {
	  await axios.post(`${window.REACT_APP_API_BASE_URL}/log-execution-time`, logData);
	} catch (error) {
	  console.error('Failed to log execution time:', error);
	}
};

export const logUserActionToBackend = async (logData) => {
	try {
	  await axios.post(`${window.REACT_APP_API_BASE_URL}/log-user-action`, logData);
	} catch (error) {
	  console.error('Failed to log user action:', error);
	}
};

export const logErrorToBackend = async (errorData) => {
	try {
	  await axios.post(`${window.REACT_APP_API_BASE_URL}/log-error`, errorData);
	} catch (loggingError) {
	  console.error('Failed to log error:', loggingError);
	}
};
  
  