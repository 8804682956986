import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth/authConfig';
import Header from './components/Header/Header';
import DisplayPDF from './components/DisplayPDF/DisplayPDF';
import AuthGuard from './components/AuthGuard/AuthGuard';

const msalInstance = new PublicClientApplication(msalConfig);

function App() {
	return (
		<MsalProvider instance={msalInstance}>
			<Router>
				<div className="App">
					<Header />
					<main>
						<Routes>
							<Route path="/" element={<Navigate to="/equipe-idp" />} />
							<Route 
								path="/equipe-idp" 
								element={
									<AuthGuard>
										<DisplayPDF key="idp" mode="IDP" />
									</AuthGuard>
								} 
							/>
							<Route 
								path="/equipe-pep" 
								element={
									<AuthGuard>
										<DisplayPDF key="pep" mode="PEP" />
									</AuthGuard>
								} 
							/>
						</Routes>
					</main>
				</div>
			</Router>
		</MsalProvider>
	);
}

export default App;